// export const WEB_API = "https://lead.anjalionline.in/api/";
// export const WEB_API_SOCKET = "https://lead.anjalionline.in";
// export const WEB_API_IMAGE = "https://lead.anjalionline.in/payment_screenshots/";

// export const WEB_API = "http://localhost:6112/api/";

// export const WEB_API_SOCKET = "http://192.168.1.70:6112";
// export const WEB_API = "http://192.168.1.70:6112/api/";
// export const WEB_API_IMAGE = "http://192.168.1.70:6112/payment_screenshots/";

export const WEB_API_SOCKET = "https://chat-node.technosters.net";
export const WEB_API = "https://chat-node.technosters.net/api/";
export const WEB_API_IMAGE = "https://chat-node.technosters.net/payment_screenshots/";
export const WEB_API_PROFILE = "https://chat-node.technosters.net/profiles/";